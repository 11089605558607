const truncate = require('truncate')
const { paramCase } = require('param-case')
const sanitizeHtml = require('sanitize-html')

const UNITS = {
  teaspoon: 'tsp',
  tablespoon: 'tbsp',
  cup: 'cup',
  count: '',
  pound: 'lb',
  gallon: 'gal',
  ounce: 'oz',
  none: '',
  can: 'can',
  quart: 'quart',
  pint: 'pint',
  'fluid ounce': 'oz',
  g: 'g',
  l: 'ltr',
  ml: 'ml',
  kg: 'kg'
}

module.exports = {
  /**
   * @method getLinkTarget
   * @description Returns the link target based on if the url contains an aol domain
   * @param url URl string
   */
  getLinkTarget: (url, force = false) => {
    if (force) {
      return '_blank'
    }

    let hostname = url.indexOf('//') > -1 ? url.split('/')[2] : 'aol.com'
    // find & remove port number
    hostname = hostname.split(':')[0]
    // find & remove "?"
    hostname = hostname.split('?')[0]

    const internalDomains = ['aol.com', 'aol.it']
    const linkOutRegex = new RegExp(internalDomains.join('|'))
    return linkOutRegex.test(hostname) ? '' : '_blank'
  },

  /**
   * @method getArticleIdFromURL
   * @description Returns the last segment of a url (article id)
   * @param url URl string
   */
  getArticleIdFromURL: url => {
    const segments = url.split('/')
    const lastSegment = segments.pop() || segments.pop()
    return lastSegment
  },

  /**
   * @method toSnakeCase
   * @description Converts all non alphanumeric characters to underscores
   * @param str string
   */
  toSnakeCase: str =>
    str
      .toLowerCase()
      .trim()
      .replace(/[\W]/gi, '_'),

  /**
   * @method toKebabCase
   * @description Converts all non alphanumeric characters to hyphens
   * @param str string
   */
  toKebabCase: str =>
    str
      .toLowerCase()
      .trim()
      .replace(/[\W_]/gi, '-'),

  /**
   * @method regexEscape
   * @description Escapes regex special chars
   * @param str string
   */
  regexEscape: str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),

  /**
   * @method snakeToKebab
   * @description converts a snake_case string to a kebab-case string
   * @param str snake_case_string
   */
  snakeToKebab: str => (typeof str === 'string' ? str.replace(/_/g, '-') : ''),

  /**
   * @method parameterize
   * @description converts a string to lower case string with dash concatenated words
   * @param str string
   */
  parameterize: (str, sep = '-') =>
    typeof str === 'string'
      ? str
          .trim()
          .toLowerCase()
          .replace(/[^a-zA-Z0-9 -]/g, '')
          .replace(/\s/g, sep)
      : '',

  truncate,

  formatCurrency: (amount, region = 'en-US', currency = 'USD') => {
    const formatter = new Intl.NumberFormat(region, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0
    })

    return formatter.format(amount)
  },

  convertUnitName: unitType => {
    const unit = unitType.toLowerCase()
    return UNITS[unit]
  },

  toBoolean: (str = '') => {
    if (typeof str === 'string') {
      return str === '1' || str.toLowerCase() === 'true' || false
    }
    return !!str
  },

  capitalizeFirstLetter: string => {
    if (string && typeof string === 'string' && string !== '') {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
    return ''
  },
  sanitizeInputHtml: string => {
    if (string && typeof string === 'string' && string !== '') {
      return sanitizeHtml(string, {
        allowedTags: [],
        allowedAttributes: {}
      })
    }
    return ''
  },

  paramCase
}
