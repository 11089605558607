const el = require('../../../lib/utils/jsx_component');
const localNewsLatestV2 = require('./local_news_latest_articles_v2');

// content blocks 1(Latest News): 0-3
// content block 2 (Top Stories) : 4-10
// content block 3 (stream):
// we are not getting stories for local news min: 0, max: 11
// recommendation would be remove block 2 and go with block 1 and block 3
module.exports = (articles = [], readMoreUrl = '', renderType = '', isLegalCompliant) => {
  const copyArticles = articles.map(v => v);
  const contentBlockLatestNews = copyArticles.splice(0, 20);
  return el("div", {
    className: "m-ln m-ln__wrapper"
  }, localNewsLatestV2(contentBlockLatestNews, readMoreUrl, isLegalCompliant));
};