const el = require('../../../lib/utils/jsx_component');
const no_data_layout = require('./no_news_layout');
const prevArrow = () => el("button", {
  disabled: true,
  className: "m-news__carousel__btn m-news__carousel__btn--prev show-for-xs-only"
}, el("div", {
  className: "m-news__carousel__btn--inner"
}, el("svg", {
  viewBox: "0 0 256 256"
}, el("path", {
  d: "M 81.1 127.9 L 175.5 34.8 C 182.8 27.6 182.9 15.6 175.5 8.3 C 168 0.9 156 0.9 148.6 8.2 L 27.2 127.9 L 148.6 247.6 C 155.9 254.7 168.1 254.8 175.5 247.5 C 183 240.1 183 228.3 175.6 221 L 81.1 127.9 Z"
}))));
const nextArrow = () => el("button", {
  className: "m-news__carousel__btn m-news__carousel__btn--next show-for-xs-only"
}, el("div", {
  className: "m-news__carousel__btn--inner"
}, el("svg", {
  viewBox: "0 0 256 256"
}, el("path", {
  d: "M 134.8 128.2 L 40.4 35.1 C 33.1 28 33 16 40.4 8.6 C 47.9 1.2 59.9 1.3 67.3 8.6 L 188.7 128.2 L 67.3 247.9 C 60 255.1 47.8 255.2 40.4 247.8 C 32.9 240.4 32.9 228.6 40.3 221.3 L 134.8 128.2 Z"
}))));
module.exports = (articles = [], readMoreUrl = '', localReadMoreUrl = '', renderType = '', slot = '', blueSkyAlt = false) => {
  const hasArticles = articles.length > 0;
  const isRR = renderType === 'right-rail';
  const href = `/${readMoreUrl}`;
  let list = null;
  if (hasArticles) {
    list = articles.map((item, index) => {
      const {
        id,
        link,
        title,
        thumbnail,
        providerLogoImgUrl,
        provider: providerDetails,
        source
      } = item;
      const providerName = source || providerDetails?.displayName || providerDetails?.sourceId;
      const targetVal = '_self';
      let gridClasses = 'cell xxs-12 xs-6 s-auto xl-6';
      if (isRR) {
        gridClasses = 'cell';
      } else if (blueSkyAlt) {
        gridClasses = 'cell xxs-12 xs-5 s-6';
      }
      const articleContentClass = thumbnail ? 'xxs-8 xs-auto xl-8' : 'noImage';
      return el("div", {
        className: `article ${gridClasses}`
      }, el("div", {
        className: "grid-x grid-margin-x"
      }, el("div", {
        className: `article-content cell auto m-news__carousel--slide ${!isRR ? articleContentClass : ''}`
      }, el("a", {
        className: "article-link",
        href: link,
        target: targetVal,
        "data-ylk": `sec:weather;subsec:local_news;elm:hdln;itc:0;ct:story;g:${id};slk:${title};cpos:${index + 1}`
      }, el("div", {
        className: "article-info"
      }, el("div", {
        className: "provider"
      }, providerLogoImgUrl ? el("img", {
        className: "provider-logo",
        src: providerLogoImgUrl,
        alt: "provider logo"
      }) : null, providerName ? el("span", {
        className: "provider-name"
      }, providerName) : null), el("span", {
        className: `title${thumbnail ? '' : ' noImage'}${isRR ? ' right-rail' : ''}`
      }, title)))), thumbnail && !isRR ? el("div", {
        className: "article-image cell xxs-4 hide-for-xs-only hide-for-s-only"
      }, el("a", {
        className: "article-img--link hide-for-xs-only hide-for-s-only",
        href: link,
        target: targetVal,
        "data-ylk": `sec:weather;subsec:local_news;elm:img;itc:0;ct:story;g:${id};slk:${title}`
      }, el("img", {
        className: "article-img",
        src: thumbnail,
        alt: title
      }))) : null));
    });
  }
  return el("div", {
    className: `m-news__inner xs-padding ${hasArticles ? 'm-news__carousel' : ''} ${isRR ? '' : ' news-list-width'}`
  }, hasArticles ? prevArrow() : null, hasArticles ? nextArrow() : null, el("div", {
    className: `m-news__lists grid-x ${isRR ? ' right-rail' : ' grid-margin-x'}`
  }, list ? list : no_data_layout(isRR, href)), articles.length > 1 && !isRR && localReadMoreUrl ? el("div", {
    className: "m-news__list--new-readmore"
  }, el("a", {
    className: "readmore",
    href: localReadMoreUrl,
    "data-ylk": "sec:weather;subsec:local_news;itc:0;slk:See all local news;elm:navcat"
  }, el("span", null, "See all local news"))) : null, articles.length > 1 && isRR && localReadMoreUrl ? el("div", {
    className: "m-news__list--new-readmore-rr"
  }, el("a", {
    className: "readmore",
    href: localReadMoreUrl,
    "data-ylk": "itc:0;slk:See all news;elm:readmore"
  }, el("span", null, "See all news >"))) : null);
};