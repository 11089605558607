const el = require('../../../lib/utils/jsx_component');
const {
  getLinkTarget
} = require('../../../lib/utils/string');
const articleMap = item => {
  const {
    id,
    link,
    title,
    thumbnail
  } = item || {};
  return el("li", {
    className: "m-news__list--item_mobile-banner"
  }, el("div", {
    className: "vertical-stream-item"
  }, thumbnail ? el("div", {
    className: "image-container"
  }, el("a", {
    className: "m-news__list--item-link",
    target: "_self",
    href: link,
    "data-ylk": `elm:hdln;g:${id}`
  }, el("img", {
    className: "vertical-stream-img",
    src: thumbnail,
    alt: title
  }))) : null, el("div", {
    className: "article-info"
  }, el("a", {
    className: "m-news__list--item-link_mobile-banner",
    target: "_self",
    href: link,
    "data-ylk": `elm:hdln;g:${id}`,
    rel: "noreferrer"
  }, el("span", {
    className: "m-news__list--item_article-info_title"
  }, title)))));
};
const getList = articles => articles.length > 0 ? articles.map(articleMap) : el("li", {
  className: "m-news__list--nonews"
}, "No local news available");
module.exports = (articles = [], readMoreUrl = '') => {
  const list = getList(articles);
  const href = `/${readMoreUrl}`;
  return el("div", {
    className: "m-news__inner-mobile_banner"
  }, el("h3", {
    className: "m-news__heading-mobile_banner"
  }, "Local News"), el("div", {
    className: "m-news__list_items_mobile_banner"
  }, el("ul", {
    className: "m-news__list_mobile_banner"
  }, list), articles.length > 1 ? el("div", {
    className: "m-news__list--readmore_mobile-banner"
  }, el("a", {
    className: "m-news__list--readmore_mobile-banner-button",
    href: href,
    "data-ylk": "itc:0;slk:Read More;elm:readmore"
  }, "See all Local News")) : null));
};