const el = require('../../../lib/utils/jsx_component');
module.exports = (isRR = '', href) => {
  return el("div", {
    className: "cell auto"
  }, el("div", {
    className: 'no-data-layout grid-x' + (isRR ? '' : ' grid-margin-x')
  }, el("div", {
    className: 'dummy-article cell grid-x' + (isRR ? ' xxs-12' : ' xs-6')
  }, el("div", {
    className: "content cell auto"
  }, el("div", {
    className: "lin-1"
  }), el("div", {
    className: "lin-2"
  }), el("div", {
    className: "lin-3"
  })), el("div", {
    className: 'img cell' + (isRR ? ' xxs-3' : ' xxs-4 m-3 l-3')
  })), el("div", {
    className: 'dummy-article cell grid-x' + (isRR ? ' xxs-12' : ' xs-6')
  }, el("div", {
    className: "content cell auto"
  }, el("div", {
    className: "lin-1"
  }), el("div", {
    className: "lin-2"
  }), el("div", {
    className: "lin-3"
  })), el("div", {
    className: 'img cell' + (isRR ? ' xxs-3' : ' xxs-4 m-3 l-3')
  })), el("div", {
    className: 'dummy-article cell grid-x' + (isRR ? ' xxs-12  ' : ' hide-for-xs-only s-6')
  }, el("div", {
    className: "content cell auto"
  }, el("div", {
    className: "lin-1"
  }), el("div", {
    className: "lin-2"
  }), el("div", {
    className: "lin-3"
  })), el("div", {
    className: 'img cell' + (isRR ? ' xxs-3' : ' xxs-4 m-3 l-3')
  })), el("div", {
    className: 'dummy-article cell grid-x no-show' + (isRR ? ' display-none' : ' hide-for-xs-only s-6')
  }, el("div", {
    className: "content cell auto"
  }, el("div", {
    className: "lin-1"
  }), el("div", {
    className: "lin-2"
  }), el("div", {
    className: "lin-3"
  })), el("div", {
    className: 'img cell' + (isRR ? ' xxs-3' : ' xxs-4 m-3 l-3')
  })), el("div", {
    className: "error-message"
  }, el("div", {
    className: "error-message__text"
  }, el("div", {
    className: "message"
  }, el("h1", null, "Local news not available now."), el("p", null, "We have our best people on it and should have things up and running soon.")), el("div", {
    className: "news-btn hide"
  }, el("a", {
    className: "hide",
    href: href,
    "data-ylk": "itc:0;slk:Check out AOL News;elm:readmore"
  }, el("span", {
    className: "news-btn__text"
  }, "Check out AOL News")))))));
};