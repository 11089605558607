const el = require('../../../lib/utils/jsx_component');
const {
  getLinkTarget
} = require('../../../lib/utils/string');
module.exports = (articles = [], readMoreUrl = '') => {
  const list = articles.length > 0 ? articles.map(({
    id,
    link,
    title
  }) => el("li", {
    className: "m-news__list--item"
  }, el("a", {
    className: "m-news__list--link",
    href: link,
    "data-ylk": `elm:hdln;g:${id}`
  }, title))) : el("li", {
    className: "m-news__list--nonews"
  }, "No local news available");
  const href = `/${readMoreUrl}`;
  return el("div", {
    className: 'm-news__inner'
  }, el("ul", {
    className: "m-news__list"
  }, list), articles.length > 1 ? el("a", {
    className: "m-news__list--readmore",
    href: href,
    "data-ylk": "itc:0;slk:Read More;elm:readmore"
  }, "Read more >") : null);
};