const el = require('../../../lib/utils/jsx_component');
const noDataLayout = require('./no_news_layout');
const {
  getLinkTarget
} = require('../../../lib/utils/string');
const {
  ylkObjToString
} = require('../../../lib/utils/rapid');
const articleHTML = (item, index, isLegalCompliant = false) => {
  const {
    id,
    link,
    title,
    thumbnail,
    provider,
    providerLogoImgUrl,
    summary,
    contentType
  } = item || {};
  const isThumbnailEmpty = thumbnail ? '' : 'imgContainerEmpty';
  return el("div", {
    className: `m-ln__content-list ${isLegalCompliant ? 'compliant' : ''}`
  }, el("a", {
    className: "m-ln__link",
    href: link,
    target: getLinkTarget(link, false),
    "data-ylk": ylkObjToString({
      elm: 'hdln',
      g: `${id}`,
      sec: 'stream',
      subsec: 'local_news',
      slk: `${encodeURIComponent(title)}`
    })
  }, el("div", {
    className: "grid-x grid-margin-x"
  }, el("div", {
    className: `article-item cell ${isThumbnailEmpty}`
  }, providerLogoImgUrl ? el("img", {
    className: "m-ln__provider-logo",
    src: providerLogoImgUrl,
    alt: "provider logo"
  }) : null, el("span", {
    className: "m-ln__provider"
  }, provider?.displayName || ''), el("h2", {
    className: "m-ln__heading"
  }, title), el("p", {
    className: "m-ln__excerpt"
  }, summary)), thumbnail ? el("div", {
    className: "image_container cell"
  }, el("div", {
    className: "m-ln__image"
  }, el("img", {
    src: thumbnail,
    alt: title
  }))) : null)));
};
module.exports = (articles = [], readMoreUrl = '', isLegalCompliant = false) => {
  const localNewsLatest = articles.length > 0 ? el("div", {
    className: "m-ln__body grid-x grid-margin-x"
  }, el("div", {
    className: "m-ln__content m-n__content--right cell"
  }, articles.map((item, index) => articleHTML(item, index, isLegalCompliant)))) : noDataLayout();
  const localNewsLatestHeader = el("h2", {
    className: "m-ln__title"
  }, " Latest News ");
  return el("div", {
    className: "m-ln__latest m-ln--4",
    "data-rapid": "true",
    "data-ylk": "subsec:local-news;ll2:local-news;mpos:1;"
  }, el("div", null, localNewsLatestHeader), el("div", null, localNewsLatest));
};